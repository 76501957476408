import { Tag } from 'entities/tag'

export const tags: Tag[] = [
    {
        id: 'd72af951-daa8-4dd3-88e1-c548ec087552',
        type: 'tech',
        subtype: 'software',
        label: 'Kotlin',
        slug: 'kotlin',
        weight: 0.029,
    },
    {
        id: '63ae5f37-849c-4283-87c6-1c47f27e0595',
        slug: 'rust',
        type: 'tech',
        subtype: 'software',
        label: 'Rust',
        weight: 0.021,
    },
    {
        id: '2d754939-37e7-41bd-8ed0-1cc783c9f7e7',
        slug: 'python',
        type: 'tech',
        subtype: 'software',
        label: 'Python',
        weight: 0.019,
    },
    {
        id: 'ebe14bbd-509e-4ae0-bd66-809c9ea6509a',
        slug: 'node-js',
        type: 'tech',
        subtype: 'software',
        label: 'Node.js',
        weight: 0.015,
    },
    {
        id: '8b1a463a-d21e-4900-b8a6-88c2efc491f4',
        slug: 'javascript',
        type: 'tech',
        subtype: 'software',
        label: 'JavaScript',
        weight: 0.013,
    },
    {
        id: '2ed111cc-8e15-4114-a9ec-bfcb774a1648',
        slug: 'health-insurance',
        type: 'benefit',
        subtype: 'health',
        label: 'Health Insurance',
        weight: 0.013,
    },
    {
        id: 'a6040a4c-9003-43e5-aadb-99f258c84da9',
        slug: 'objective-c',
        type: 'tech',
        subtype: 'software',
        label: 'Objective-C',
        weight: 0.005,
    },
    {
        id: 'b11fc9cf-b852-410f-b61d-7725e0ccfcc1',
        slug: 'react',
        type: 'tech',
        subtype: 'software',
        label: 'React',
        weight: 0.01,
    },
    {
        id: '4df8396e-2395-4a4f-af80-4ea1fe806f81',
        slug: 'docker',
        type: 'tech',
        subtype: 'ops',
        label: 'Docker',
        weight: 0.001,
    },
    {
        id: '65d7f4ba-160d-4746-85d1-02112944a3b2',
        slug: 'ci-cd',
        type: 'tech',
        subtype: 'ops',
        label: 'CI/CD',
        weight: 0,
    },
    {
        id: 'f9ea5ff9-2813-4c6b-97ee-30f68a18926d',
        slug: 'solidity',
        type: 'tech',
        subtype: 'software',
        label: 'Solidity',
        weight: 0,
    },
    {
        id: '439849fd-7f4a-4101-a613-135226049ad9',
        slug: '401-k-',
        type: 'benefit',
        subtype: 'finance',
        label: '401(K)',
        weight: 0,
    },
    {
        id: 'f2262855-ffc5-42d7-8559-054d03823750',
        slug: 'daily-stand-up',
        type: 'benefit',
        subtype: 'culture',
        label: 'Daily Stand Up',
        weight: 0,
    },
    {
        id: 'f865b1b1-4f65-43b0-827c-8a6a7e460106',
        slug: 'blockchain',
        type: 'industry',
        label: 'Blockchain',
        weight: 0,
    },
    {
        id: '546925f5-4e7e-49bf-a673-857c9a813d6a',
        slug: 'tickets-to-live-shows-filming',
        type: 'benefit',
        subtype: 'extra',
        label: 'Tickets To Live Shows & Filming',
        weight: 0,
    },
    {
        id: '9cf2a8b6-8da1-4d7f-9f2a-cdbec060191b',
        slug: 'react-native',
        type: 'tech',
        subtype: 'software',
        label: 'React Native',
        weight: 0,
    },
    {
        id: '856199ca-5231-4a3c-af09-172c85441a5f',
        slug: 'typescript',
        type: 'tech',
        subtype: 'software',
        label: 'TypeScript',
        weight: 0,
    },
    {
        id: 'e0491140-2884-4630-a4de-c67eaacfd249',
        slug: 'education-benefits',
        type: 'benefit',
        subtype: 'professional_dev',
        label: 'education benefits',
        weight: 0,
    },
    {
        id: 'a7edf94c-5c8d-48e3-9e59-1b751004e8d6',
        slug: 'aws',
        type: 'tech',
        subtype: 'infrastructure',
        label: 'AWS',
        weight: 0,
    },
    {
        id: '8fc33102-8630-4cb0-b161-661ba4726a52',
        slug: 'series-a',
        type: 'funding',
        label: 'Series A',
        weight: 0,
    },
    {
        id: '958ecfb1-f9fd-4a01-bc26-7afed5a25807',
        slug: 'token-equity',
        type: 'benefit',
        subtype: 'finance',
        label: 'Token Equity',
        weight: 0,
    },
    {
        id: '6321bcb5-86c3-4bfb-9f84-e523b5b44d03',
        slug: 'unlimited-vacation-policy',
        type: 'benefit',
        subtype: 'vacation',
        label: 'Unlimited Vacation Policy',
        weight: 0,
    },
    {
        id: '4deeed7b-bb36-40bd-9985-0da6b736683a',
        slug: '100-remote-working',
        type: 'benefit',
        subtype: 'culture',
        label: '100% remote working',
        weight: 0,
    },
    {
        id: '06088c95-6b64-46c3-8957-3f46f5f001d0',
        slug: 'systems-development',
        type: 'tech',
        subtype: 'software',
        label: 'systems development',
        weight: 0,
    },
]
