import { currencyIdToSymbol } from 'entities/currency'
import { AllRemunerationTimePeriods, RemunerationTimePeriod } from 'entities/job'
import { formatAmount } from 'lib/number'

export type Remuneration = {
    competitive?: boolean
    timePeriod?: RemunerationTimePeriod
    equity?: boolean
    currency?: string
    min?: number
    max?: number
}

export const formatRemuneration = (remuneration: Remuneration) => {
    if (remuneration.competitive) {
        return 'Competitive'
    }
    return formatSalary(remuneration)
}

const formatSalary = ({
    min,
    max,
    currency,
    timePeriod,
    equity,
}: Pick<Remuneration, 'min' | 'max' | 'currency' | 'timePeriod' | 'equity'>) => {
    const fmtMin = min && formatAmount(min)
    const fmtMax = max && formatAmount(max)
    const symbol = currency ? currencyIdToSymbol[currency] ?? currency : ''

    let salary
    if (fmtMin && fmtMax) {
        salary = `${symbol}${fmtMin} - ${fmtMax}`
    } else if (fmtMin) {
        salary = `from ${symbol}${fmtMin}`
    } else if (fmtMax) {
        salary = `up to ${symbol}${fmtMax}`
    }
    if (timePeriod === AllRemunerationTimePeriods.Daily) {
        salary += ' per day'
    }

    return [salary, equity && 'Equity'].filter(Boolean).join(' + ')
}
