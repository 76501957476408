export const CURRENCY_ID_USD = 'USD'
export const CURRENCY_ID_EUR = 'EUR'
export const CURRENCY_ID_GBP = 'GBP'

export const currencyIds = [
    CURRENCY_ID_USD,
    CURRENCY_ID_EUR,
    CURRENCY_ID_GBP,
    'BTC',
    'AUD',
    'CAD',
    'CHF',
    'NOK',
    'SEK',
    'PLN',
    'YEN',
    'HKD',
    'SGD',
    'INR',
    'BRL',
]

export const currencyIdToSymbol: Record<string, string> = {
    [CURRENCY_ID_USD]: '$',
    [CURRENCY_ID_EUR]: '€',
    [CURRENCY_ID_GBP]: '£',
    BTC: '₿',
    CHF: '₣',
    AUD: 'A$',
    SGD: 'S$',
    CAD: 'C$',
    HKD: 'HK$',
    NOK: 'kr',
    SEK: 'kr',
    PLN: 'zł',
    YEN: '¥',
    INR: '₹',
    BRL: 'R$',
}

export const withSymbol = (currency: string) => {
    const currencySymbol = currencyIdToSymbol[currency]
    return `${currency} ${currencySymbol ? '(' + currencySymbol + ')' : ''}`
}
