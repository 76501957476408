/**
 * Returns a percentage string.
 *
 * Examples:
 * numberToPercentage(0.01)   = "1"
 * numberToPercentage(0.5)    = "50"
 * numberToPercentage(0.75)   = "75"
 * numberToPercentage(0.995)  = "100"
 * numberToPercentage(-0.995) = "-100"
 */
export const numberToPercentage = (n: number) => (n * 100).toFixed(0)

const isInRange = (range: 'K' | 'M', n: number) => {
    switch (range) {
        case 'M':
            return Math.abs(n) >= 999500
        case 'K':
            return Math.abs(n) >= 950
    }
}

/**
 * Formats a number (in a locale-ignoring way).
 */
export const formatNumber = (n: number) => {
    return Intl.NumberFormat('en-GB').format(n)
}

const dropFractional = (numStr: string) => {
    return numStr.replace(/\.[0-9]*$/, '')
}

/**
 * Formats a (monetary) amount.
 *
 * N >= 1,000,000 -> "NM"
 * N >= 1,000     -> "NK"
 * other N        -> "N"
 *
 * Examples:
 * formatAmount(100)    = "100"
 * formatAmount(1000)   = "1K"
 * formatAmount(1500)   = "2K"
 * formatAmount(999500) = "1M"
 * formatAmount(-99500) = "-100K"
 *
 * formatAmount(100.5)    = "101"
 * formatAmount(1000.5)   = "1K"
 * formatAmount(1500.5)   = "2K"
 * formatAmount(999500.5) = "1M"
 * formatAmount(-99500.5) = "-100K"
 *
 * formatAmount(100.5, true)    = "100.5"
 * formatAmount(1000.5, true)   = "1.0K"
 * formatAmount(1500.5, true)   = "1.5K"
 * formatAmount(999500.5, true) = "1.0M"
 * formatAmount(-99500.5, true) = "-99.5K"
 *
 * formatAmount(100.5, true, true)    = "100.5"
 * formatAmount(1000.5, true, true)   = "1K"
 * formatAmount(1500.5, true, true)   = "1.5K"
 * formatAmount(999500.5, true, true) = "1M"
 * formatAmount(-99500.5, true, true) = "-99.5K"
 */
export const formatAmount = (
    n: number,
    decimal?: boolean,
    dropZeroFraction?: boolean,
) => {
    let numStr, postfixStr
    if (isInRange('M', n)) {
        numStr = (n / 1000000).toFixed(decimal ? 1 : 0)
        postfixStr = 'M'
    } else if (isInRange('K', n)) {
        numStr = (n / 1000).toFixed(decimal ? 1 : 0)
        postfixStr = 'K'
    } else {
        numStr = `${n.toFixed(decimal ? 1 : 0)}`
        postfixStr = ''
    }
    if (dropZeroFraction && numStr.endsWith('.0')) {
        numStr = dropFractional(numStr)
    }
    return numStr + postfixStr
}

/**
 * The same as `formatAmount`, but returns a value with its sign:
 *
 * N ∈ { 1 .. +Inf} -> +N
 * N = 0            ->  0
 * N ∈ {-Inf .. -1} -> -N
 *
 * Examples:
 * formatAmountSigned(100)    = "+100"
 * formatAmountSigned(1000)   = "+1K"
 * formatAmountSigned(1500)   = "+2K"
 * formatAmountSigned(999500) = "+1M"
 * formatAmountSigned(0)      = "0"
 * formatAmountSigned(-99500) = "-100K"
 */
export const formatAmountSigned = (n: number) => {
    return n === 0 ? '0' : `${n > 0 ? '+' : ''}${formatAmount(n)}`
}

/**
 * Returns a rounded (monetary) amount.
 *
 * Examples:
 * roundAmount(100)    = 100
 * roundAmount(1000)   = 1000
 * roundAmount(1500)   = 2000
 * roundAmount(999500) = 1000000
 * roundAmount(-99500) = -99000
 */
export const roundAmount = (n: number): number => {
    if (isInRange('M', n)) {
        return Math.round(n / 1000000) * 1000000
    } else if (isInRange('K', n)) {
        return Math.round(n / 1000) * 1000
    } else {
        return Math.round(n)
    }
}

/**
 * Returns a number of digits in a number.
 * See https://stackoverflow.com/a/28203456
 */
export const numberOfDigits = (n: number): number => {
    return (Math.log10((n ^ (n >> 31)) - (n >> 31)) | 0) + 1
}

/**
 * A set of basic denominations used in currency systems.
 */
const basicTokenAmounts = [1, 2, 5]

/**
 * Returns an array of denominations for a specified power of 10.
 *
 * @param exp — a power of 10 up to which you want to tokenize
 */
export const tokenizeAmount = (exp: number = 3): number[] => {
    const powersOfTen = Array(exp)
        .fill(0)
        .map((_, i) => Math.pow(10, i))
    return basicTokenAmounts
        .flatMap(n => powersOfTen.map(k => k * n))
        .sort((a, b) => a - b)
}
