import React, { useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Flex, TagLabel, Text, Wrap, WrapItem, WrapProps } from '@chakra-ui/react'
import { CSSObject, chakra, useStyleConfig } from '@chakra-ui/system'
import { Tag as TagEntity } from 'entities/tag'
import { splitThemingProps } from 'lib/chakra'
import { Tag, TagProps } from 'components/tags/Tag'

const ChevronDownIconChakra = chakra(ChevronDownIcon)
const ChevronUpIconChakra = chakra(ChevronUpIcon)

const ShowMoreTags = ({
    tagStyles,
    handleClick,
}: {
    /**
     * Used for syncing the typography styles with the `Tag`'s ones.
     */
    tagStyles: CSSObject
    handleClick: (isExpanded: boolean) => void
}) => {
    const [isExpanded, setExpanded] = useState(false)

    // @ts-ignore
    const { container: containerStyles, label: labelStyles } = tagStyles

    return (
        <Flex
            p={containerStyles.padding}
            gridGap="8px"
            alignItems="center"
            cursor="pointer"
            onClick={() => {
                setExpanded(!isExpanded)
                handleClick(!isExpanded)
            }}
        >
            {isExpanded ? (
                <>
                    <Text variant="functionalSemiBold" {...labelStyles}>
                        less
                    </Text>
                    <ChevronUpIconChakra boxSize="20px" strokeWidth={1.5} />
                </>
            ) : (
                <>
                    <Text variant="functionalSemiBold" {...labelStyles}>
                        more
                    </Text>
                    <ChevronDownIconChakra boxSize="20px" strokeWidth={1.5} />
                </>
            )}
        </Flex>
    )
}

export type ComponentTag = Pick<TagEntity, 'id' | 'label'> & {
    slug?: string // made optional for non-link tags
    isDisabled?: boolean
    isSelected?: boolean
}

export type TagsProps = {
    tags: ComponentTag[]
    isLoaded?: boolean
    isDisabled?: boolean
    buildHref?: ({ slug }: { slug: string }) => string
    handleTagClick?: (tag: ComponentTag) => void
    addShowMore?: boolean
    handleShowMoreClick?: (isExpanded: boolean) => void
} & WrapProps

export function Tags(props: TagsProps & TagProps) {
    const [tagProps, tagsProps] = splitThemingProps(props, [])
    const {
        tags,
        isLoaded = true,
        isDisabled,
        buildHref,
        handleTagClick,
        addShowMore,
        handleShowMoreClick,
        ...wrapProps
    } = tagsProps

    const tagStyles = useStyleConfig('Tag', tagProps)

    if (addShowMore && handleShowMoreClick === undefined) {
        throw new Error("The 'show more' option is enabled without the click handler")
    }

    if (!tags || tags.length === 0) {
        return null
    }

    return (
        <Wrap justifyContent="flex-start" spacing="4px" flexWrap="wrap" {...wrapProps}>
            {tags.map(tag => {
                return (
                    <WrapItem key={tag.id}>
                        <Tag
                            href={buildHref && buildHref({ slug: tag.slug || '' })}
                            onClick={handleTagClick && (() => handleTagClick(tag))}
                            isLoaded={isLoaded}
                            data-disabled={isDisabled || tag.isDisabled}
                            data-selected={tag.isSelected}
                            // NB: `Tag` parts — `container`, `label`, and `closeButton` —
                            //     are styled via Chakra theme, not props. Don't pass them.
                            {...tagProps}
                        >
                            <TagLabel>{tag.label}</TagLabel>
                        </Tag>
                    </WrapItem>
                )
            })}
            {addShowMore ? (
                <ShowMoreTags tagStyles={tagStyles} handleClick={handleShowMoreClick!} />
            ) : null}
        </Wrap>
    )
}
