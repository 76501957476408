import { Company } from 'entities/company'
import { Location } from 'entities/location'
import { Remuneration } from 'entities/remuneration'
import { Tag } from 'entities/tag'
import { Vertical } from 'entities/vertical'
import { nonBreakingItemsJoin } from 'lib/text'

// interfaces

export type JobTag = Pick<Tag, 'id' | 'label' | 'slug'>
export const AllImportance = {
    NotImportant: 'not_important',
    Important: 'important',
    NiceToHave: 'nice_to_have',
} as const

export type EducationImportance = typeof AllImportance[keyof typeof AllImportance]

export const AllExperienceLevels = {
    Beginner: 'beginner',
    Intermediate: 'intermediate',
    Advanced: 'advanced',
} as const

export type ExperienceLevel = typeof AllExperienceLevels[keyof typeof AllExperienceLevels]

export const AllRoleTypes = {
    Intern: 'Intern',
    Contract: 'Contract',
    FullTime: 'Full time',
} as const

export type RoleType = typeof AllRoleTypes[keyof typeof AllRoleTypes]

export type SpokenLanguage = {
    name: string
    level: ExperienceLevel
}

export type TechExperienceRequirement = {
    id: Tag['id']
    level: ExperienceLevel
}

export const AllRemunerationTimePeriods = {
    Yearly: 'Yearly',
    Daily: 'Daily',
} as const

export type RemunerationTimePeriod = typeof AllRemunerationTimePeriods[keyof typeof AllRemunerationTimePeriods]

export type TimezoneRestriction = {
    timezoneName: string
    timezoneDelta: { minus: number; plus: number }
}

export type RemoteInfo = {
    regionRestrictions?: string[]
    timezoneRestrictions?: TimezoneRestriction[]
}

export type Affiliate = {
    source: string
    url: string
}

export interface AnyJob {
    id: string
    slug: string
    tags: JobTag[]
    verticals: Vertical[]
    title: string
    companyId?: string
    // TODO: update schema, tagline
    tagline: string
    yearsOfCommercialExperience?: number
    formalEducation?: EducationImportance
    spokenLanguages?: SpokenLanguage[]
    techExperienceRequirements?: TechExperienceRequirement[]
    // TODO: update schema, description html
    descriptionHtml: string
    manager?: string
    approved?: boolean
    archived?: boolean
    published?: boolean
    roleType: RoleType
    remote?: boolean
    remuneration?: Remuneration
    location?: Location
    sponsorshipOffered?: boolean
    atsJobId: '' | (string & {})
    atsJobName?: string
    remoteInfo?: RemoteInfo
    // TODO: update schema, role type
    company: CompanyJob
    lastModified?: string
    // for company matches
    matchingUsers?: number
    // for candidate matches
    userScore?: number
}

export type InternalJob = AnyJob & {
    affiliate: null
}

export type AffiliateJob = AnyJob & {
    affiliate: Affiliate
}

export type Job = InternalJob | AffiliateJob

export const defaultVerticalToCreateJob: Vertical = 'functional'

// context projections

export type CompanyJob = Pick<
    Company,
    'id' | 'slug' | 'name' | 'logo' | 'locations' | 'profileEnabled'
>

// helper functions

export const formatJobLocation = ({
    location,
    remote,
}: Pick<Job, 'location' | 'remote'>) => {
    const { country, countryCode, city, state } = location ?? {}

    // NB: Implementation adopted from the `wh.common.job/format-job-location`.
    const arr = (() => {
        if (remote) {
            return [country]
        } else if (city && countryCode?.toLowerCase() === 'us') {
            return [city, state, countryCode]
        } else if (city) {
            return [city, country]
        } else if (!city && state) {
            return [state, country]
        } else if (!city && !state) {
            return [country]
        }
    })()

    if (arr) {
        return nonBreakingItemsJoin(arr)
    }
}

export const formatJobRegionRestrictions = (
    {
        remoteInfo,
    }: {
        remoteInfo?: Pick<RemoteInfo, 'regionRestrictions'>
    },
    remoteLabel?: string,
) => {
    // NB: There are times when we can't get RemoteInfo, e.g. at the Jobsboard,
    //     so we can't be sure the job is 'Remote worldwide'. Moreover, usually
    //     it's not. So we pass here a default `remoteLabel` for such cases.
    if (remoteInfo?.regionRestrictions && remoteInfo?.regionRestrictions.length > 0) {
        return `Remote within ${nonBreakingItemsJoin(remoteInfo?.regionRestrictions)}`
    } else {
        return remoteLabel ?? 'Remote worldwide'
    }
}

export const formatJobTimezoneRestrictions = ({
    remoteInfo,
}: {
    remoteInfo?: Pick<RemoteInfo, 'timezoneRestrictions'>
}): string | undefined => {
    if (remoteInfo?.timezoneRestrictions && remoteInfo?.timezoneRestrictions.length > 0) {
        const timezones = remoteInfo?.timezoneRestrictions.map(timezone => {
            const minus = Math.abs(timezone?.timezoneDelta?.minus)
            const plus = Math.abs(timezone?.timezoneDelta?.plus)
            return `${timezone?.timezoneName} (–${minus}/+${plus} h)`
        })
        return nonBreakingItemsJoin(timezones)
    }
}

export const formatJobLocationInfo = (
    {
        remote,
        remoteInfo,
        location,
    }: Pick<Job, 'remote' | 'remoteInfo' | 'location'> & {
        remoteInfo?: Pick<RemoteInfo, 'regionRestrictions'>
    },
    remoteLabel?: string,
) => {
    if (remote) {
        const regions = formatJobRegionRestrictions(
            {
                remoteInfo,
            },
            remoteLabel,
        )
        const timezones = formatJobTimezoneRestrictions({ remoteInfo })
        return [regions, timezones].filter(Boolean).join(', ')
    } else {
        return formatJobLocation({
            remote,
            location,
        })
    }
}

export const isJobOwner = (
    job: Pick<Job, 'companyId'>,
    myCompany?: Pick<Company, 'id'>,
): boolean => {
    return myCompany?.id === job.companyId
}

const MATCH_THRESHOLD = 0.5

export const candidateHasMatch = (job?: Pick<Job, 'userScore'>): boolean => {
    return (job?.userScore ?? 0) >= MATCH_THRESHOLD
}

export const companyHasMatch = (job?: Pick<Job, 'matchingUsers'>): boolean => {
    return !!job?.matchingUsers && job.matchingUsers > 0
}
