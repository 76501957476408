import { ThemingProps } from '@chakra-ui/system'
import { split } from '@chakra-ui/utils'

/**
 * Alike Chakra's `omitThemingProps` from "@chakra-ui/system", but does `split`
 * instead of `omit`. Pass `extraKeys` optional param to split over more keys.
 */
export function splitThemingProps<T extends ThemingProps, K extends keyof T>(
    props: T,
    extraKeys: K[] = [],
) {
    return split(props, ['styleConfig', 'size', 'variant', 'colorScheme', ...extraKeys])
}
