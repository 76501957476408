import React from 'react'
import { Tag as ChakraTag, TagProps as ChakraTagProps, Skeleton } from '@chakra-ui/react'
import Link from 'next/link'

export type TagProps = ChakraTagProps & {
    href?: string
    isLoaded?: boolean
    'data-disabled'?: boolean
    'data-selected'?: boolean
}

export const Tag = (props: TagProps) => {
    const { href, isLoaded = true, ...restProps } = props
    const isDisabled = props['data-disabled']

    return (
        <Skeleton
            isLoaded={isLoaded}
            // NB: Get parent <div> out of the way when is loaded.
            display={isLoaded ? 'contents' : 'block'}
        >
            {isDisabled ? (
                // NB: Gets rendered as <span>.
                <ChakraTag {...restProps} />
            ) : href ? (
                <Link href={href} passHref>
                    <ChakraTag as="a" {...restProps} />
                </Link>
            ) : (
                // NB: Having it rendered as `button` is an easiest way to have it
                //     properly focusable and auto-handling some key press events.
                <ChakraTag
                    as={props.onClick ? 'button' : undefined}
                    type={props.onClick ? 'button' : undefined}
                    {...restProps}
                />
            )}
        </Skeleton>
    )
}
